
if (typeof email !== "undefined" && typeof username !== "undefined") {
    Vue.createApp({
        data() {
            return {
                formData: {
                    // eslint-disable-next-line no-undef
                    email,
                    // eslint-disable-next-line no-undef
                    username,
                    oldPassword: "",
                    password: "",
                    passwordConfirm: "",
                    // eslint-disable-next-line no-undef
                    mastodon: mastodon || {
                        publish: false,
                        url: "",
                        token: "",
                        message:
                            "Je viens d'ajouter {artist} - {album} à ma collection !",
                    },
                },
                loading: false,
                errors: [],
            };
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            async testMastodon() {
                const { url, token } = this.formData.mastodon;

                if (!url) {
                    this.errors.push("emptyUrl");
                }
                if (!token) {
                    this.errors.push("emptyToken");
                }

                if (this.errors.length > 0) {
                    return false;
                }

                try {
                    await axios.post(`/api/v1/mastodon`, { url, token });

                    showToastr("Configuration valide !", true);
                } catch (err) {
                    showToastr(
                        err.response?.data?.message ||
                            "Impossible de tester cette configuration",
                        false
                    );
                }

                return true;
            },
            // eslint-disable-next-line no-unused-vars
            async updateProfil() {
                this.errors = [];
                const { oldPassword, password, passwordConfirm, mastodon } =
                    this.formData;

                if (password && !oldPassword) {
                    this.errors.push("emptyPassword");
                }

                if (password !== passwordConfirm) {
                    this.errors.push("passwordsDiffer");
                }

                if (this.errors.length > 0) {
                    return false;
                }

                this.loading = true;

                const data = {
                    mastodon,
                };

                if (password) {
                    data.password = password;
                    data.oldPassword = oldPassword;
                }

                try {
                    await axios.patch(`/api/v1/me`, data);

                    showToastr("Profil mis à jour", true);
                } catch (err) {
                    showToastr(
                        err.response?.data?.message ||
                            "Impossible de mettre à jour votre profil"
                    );
                }

                this.loading = false;

                return true;
            },
        },
    }).mount("#mon-compte");
}
