/**
 * Fonction permettant de sauvegarder dans le stockage local le choix du thème
 * @param {String} scheme
 */
function saveColorScheme(scheme) {
    localStorage.setItem("theme", scheme);
}

/**
 * Fonction permettant de changer le thème du site
 * @param {String} scheme
 */
function setColorScheme(scheme) {
    document.documentElement.setAttribute("data-theme", scheme);
}

/**
 * Fonction permettant de récupérer le thème du système
 * @return {String}
 */
function getPreferredColorScheme() {
    if (window.matchMedia) {
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            return "dark";
        }
        return "light";
    }
    return "light";
}

// INFO: On place un event sur le bouton
const toggleSwitch = document.querySelector(
    '.theme-switch input[type="checkbox"]'
);

/**
 * Event permettant de détecter les changements de thème du système
 */
if (window.matchMedia) {
    const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    colorSchemeQuery.addEventListener("change", () => {
        const selectedColorScheme = localStorage.getItem("theme") || "system";

        if (selectedColorScheme === "system") {
            const preferedColorScheme = getPreferredColorScheme();
            setColorScheme(preferedColorScheme);

            toggleSwitch.checked = preferedColorScheme === "dark";
        }
    });
}

const currentTheme = localStorage.getItem("theme") || getPreferredColorScheme();

// INFO: Au chargement de la page on détecte le thème à charger
setColorScheme(currentTheme);

toggleSwitch.checked = currentTheme === "dark";

toggleSwitch.addEventListener(
    "change",
    (e) => {
        e.preventDefault();

        const scheme = e.target.checked ? "dark" : "light";

        saveColorScheme(scheme);
        setColorScheme(scheme);
    },
    false
);
