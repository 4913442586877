if (typeof item !== "undefined") {
    Vue.createApp({
        data() {
            return {
                // eslint-disable-next-line no-undef
                item,
                // eslint-disable-next-line no-undef
                canShareItem,
                tracklist: [],
                identifiers: [],
                modalIsVisible: false,
                identifiersMode: "preview",
                identifiersPreviewLength: 16,
                preview: null,
                index: null,
                showModalDelete: false,
                showModalShare: false,
                shareMessage: "",
                shareMessageTransformed: "",
                shareMessageLength: 0,
                shareSubmiting: false,
            };
        },
        created() {
            this.setTrackList();
            this.setIdentifiers();

            window.addEventListener("keydown", this.changeImage);
        },
        destroyed() {
            window.removeEventListener("keydown", this.changeImage);
        },
        watch: {
            shareMessage(message) {
                const video =
                    this.item.videos && this.item.videos.length > 0
                        ? this.item.videos[0].uri
                        : "";

                this.shareMessageTransformed = message
                    .replaceAll("{artist}", this.item.artists[0].name)
                    .replaceAll("{format}", this.item.formats[0].name)
                    .replaceAll("{year}", this.item.year)
                    .replaceAll("{video}", video)
                    .replaceAll("{album}", this.item.title);

                this.shareMessageLength = this.shareMessageTransformed.replace(
                    video,
                    new Array(36).join("#")
                ).length;
            },
        },
        methods: {
            setIdentifiers() {
                this.identifiers = [];

                const max =
                    this.identifiersMode === "preview" &&
                    this.item.identifiers.length > this.identifiersPreviewLength
                        ? this.identifiersPreviewLength
                        : this.item.identifiers.length;

                for (let i = 0; i < max; i += 1) {
                    this.identifiers.push(this.item.identifiers[i]);
                }
            },
            setTrackList() {
                this.tracklist = [];
                let subTrack = {
                    type: null,
                    title: null,
                    tracks: [],
                };
                for (let i = 0; i < this.item.tracklist.length; i += 1) {
                    const {
                        type_,
                        title,
                        position,
                        duration,
                        artists,
                        extraartists,
                    } = this.item.tracklist[i];

                    if (type_ === "heading") {
                        if (subTrack.type) {
                            this.tracklist.push(subTrack);
                            subTrack = {
                                type: null,
                                title: null,
                                tracks: [],
                            };
                        }

                        subTrack.type = type_;
                        subTrack.title = title;
                    } else {
                        subTrack.tracks.push({
                            title,
                            position,
                            duration,
                            extraartists,
                            artists,
                        });
                    }
                }
                this.tracklist.push(subTrack);
            },
            setImage() {
                this.preview = this.item.images[this.index].uri;
            },
            showGallery(event) {
                const item =
                    event.target.tagName === "IMG"
                        ? event.target.parentElement
                        : event.target;

                const { index } = item.dataset;

                this.index = Number(index);
                this.modalIsVisible = true;

                this.setImage();
            },
            toggleModal() {
                this.modalIsVisible = !this.modalIsVisible;
            },
            previous() {
                this.index =
                    this.index > 0
                        ? this.index - 1
                        : this.item.images.length - 1;
                this.setImage();
            },
            next() {
                this.index =
                    this.index + 1 === this.item.images.length
                        ? 0
                        : this.index + 1;
                this.setImage();
            },
            changeImage(event) {
                const direction = event.code;

                if (
                    this.modalIsVisible &&
                    ["ArrowRight", "ArrowLeft", "Escape"].indexOf(direction) !==
                        -1
                ) {
                    switch (direction) {
                        case "ArrowRight":
                            return this.next();
                        case "ArrowLeft":
                            return this.previous();
                        default:
                            this.modalIsVisible = false;
                            return true;
                    }
                }

                return true;
            },
            showAllIdentifiers() {
                this.identifiersMode = "all";
                this.setIdentifiers();
            },
            showLessIdentifiers() {
                this.identifiersMode = "preview";
                this.setIdentifiers();

                document
                    .querySelector("#identifiers")
                    .scrollIntoView({ behavior: "smooth" });
            },
            showConfirmDelete() {
                this.toggleModalDelete();
            },
            toggleModalDelete() {
                this.showModalDelete = !this.showModalDelete;
            },
            updateItem() {
                showToastr("Mise à jour en cours…", true);
                axios
                    .patch(`/api/v1/albums/${this.item._id}`)
                    .then((res) => {
                        showToastr("Mise à jour réalisée avec succès", true);
                        this.item = res.data;

                        this.setTrackList();
                        this.setIdentifiers();
                        this.showLessIdentifiers();
                    })
                    .catch((err) => {
                        showToastr(
                            err.response?.data?.message ||
                                "Impossible de mettre à jour cet album",
                            false
                        );
                    });
            },
            deleteItem() {
                axios
                    .delete(`/api/v1/albums/${this.item._id}`)
                    .then(() => {
                        window.location.href = "/ma-collection";
                    })
                    .catch((err) => {
                        showToastr(
                            err.response?.data?.message ||
                                "Impossible de supprimer cet album"
                        );
                    })
                    .finally(() => {
                        this.toggleModalDelete();
                    });
            },
            goToArtist() {
                return "";
            },
            shareAlbum() {
                if (this.shareSubmiting) {
                    return false;
                }
                this.shareSubmiting = true;
                axios
                    .post(`/api/v1/albums/${this.item._id}/share`, {
                        message: this.shareMessageTransformed,
                    })
                    .then(() => {
                        showToastr("Album partagé", true);
                        this.shareMessage = "";
                        this.showModalShare = false;
                    })
                    .catch((err) => {
                        showToastr(
                            err.response?.data?.message ||
                                "Impossible de partager cet album",
                            false
                        );
                    })
                    .finally(() => {
                        this.shareSubmiting = false;
                    });

                return true;
            },
        },
    }).mount("#ma-collection-details");
}
