Vue.createApp({
    data() {
        return {
            format: "xml",
        };
    },
    created() {},
    destroyed() {},
    methods: {
        exportCollection(event) {
            event.preventDefault();

            window.open(`/api/v1/albums?exportFormat=${this.format}`, "_blank");
        },
    },
}).mount("#exporter");
